<template>
  <v-card class="none-overflow" v-ripple @click="english = !english">
    <div class="daily-sentence">
      <div style="width: 100%">
        <div class="mb-4 daily-sentence-title">
          <span class="sentence-title-left">今日一言</span
          ><span class="sentence-title-right"
            >{{ dailySentence.yy }}<span class="mx-1 split">/</span
            >{{ dailySentence.mm }}<span class="mx-1 split">.</span
            >{{ dailySentence.dd }}</span
          >
        </div>
        <div class="subheading font-weight-thin hide-scroll sentence-context">
          <p v-show="english">
            {{ dailySentence.sentence }}
          </p>
          <p v-show="!english">{{ dailySentence.translate }}</p>
        </div>
      </div>
    </div>
    <v-img
      :src="imgUrl"
      :lazy-src="require('@/assets/background/colorful/material1.jpg')"
      v-parallax.modifier="0.1"
      height="20rem"
      class="daily-sentence-img"
      gradient="to top right, #ffaf64, rgba(52,7,76,.6)"
    >
    </v-img>
  </v-card>
</template>

<script>
import { isEmpty } from "@/utils/common";
import $api from "@/api/api";
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "dailySentence",
  mixins: [buttonVibrate],
  data() {
    let date = new Date();
    return {
      english: true,
      sysImg: [
        require("@/assets/background/colorful/material1.jpg"),
        // require("@/assets/background/colorful/material2.png"),
        require("@/assets/background/colorful/material3.jpg"),
      ],
      dailySentence: {
        img: null,
        sentence: "Go for it, not ever give up anytime!",
        translate: "向前吧，任何时候都不要放弃！",
        yy: date.getFullYear(),
        mm: date.getMonth() + 1,
        dd: date.getDate(),
      },
    };
  },
  computed: {
    imgUrl() {
      if (isEmpty(this.dailySentence.img)) {
        let i = parseInt(Math.random() * 2);
        return this.sysImg[i];
      }
      return this.dailySentence.img;
    },
  },
  methods: {
    getDailySentence() {
      $api
        .getMotto()
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.dailySentence.img = res.data.img;
            this.dailySentence.sentence = res.data.sentence;
            this.dailySentence.translate = res.data.translate;
          } else {
            // 不弹出提示打扰用户了
            console.log("获取每日一句失败！");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getDailySentence();
  },
};
</script>

<style lang="scss" scoped>
.daily-sentence-img {
  position: absolute;
  left: 0;
  top: -2rem;
  opacity: 0.8;
}

.daily-sentence {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 1;
  position: relative;
  color: #ffffff;
  padding: 1rem;
  letter-spacing: 0.05rem;
  .daily-sentence-title {
    width: 100%;
    line-height: 1.5rem;
    letter-spacing: 0.15rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    text-shadow: 1px 1px 5px #00000085;
  }
  .sentence-context {
    text-align: justify;
    text-justify: inter-ideograph;
    font-weight: 300;
    font-size: 0.9rem;
    height: 5rem;
    overflow-y: scroll;
    text-shadow: 1px 1px 2px #00000085;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }
  .sentence-title-left {
    font-size: 1.1rem;
    font-weight: 500;
  }
  .sentence-title-right {
    font-size: 0.8rem;
    font-weight: 300;
    float: right;
    .split {
      color: #ff4b4b;
      font-weight: 700;
    }
  }
}
</style>
